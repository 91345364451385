import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import Intro from './pages/Intro';
import Search from './pages/Search';
import Games from './pages/Games';
import Result from './pages/Result';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div className="container">
          <Routes>
            <Route path="/" element={<Intro />} />
            <Route path="/Search" element={<Search />} />
            <Route path="/Games/:gameId" element={<Games />} />
            <Route path="/Result" element={<Result />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;