import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/Search.css';

import { Link } from 'react-router-dom';
import '../css/common.css';

let apiUrl = process.env.REACT_APP_API_URL;
console.log('search enter');

function Search() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState(location.state?.results?.results || []);
  const [searchTerm, setSearchTerm] = useState(new URLSearchParams(location.search).get('q') || '');
  const [expandedItems, setExpandedItems] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // 추가된 placeholder 관련 상태 관리
  const [placeholderIndex, setPlaceholderIndex] = useState(0);

  // 여러 placeholder 리스트
  const placeholders = [
    "채식 추천",
    "칼국수 맛집",
    "파스타 맛집",
    "중식 맛집",
    "일식 맛집",
    "카페 추천",
    "데이트 맛집",
    "분위기 좋은 맛집",
    "한식 맛집"
  ];

  // 1초마다 placeholder 변경
  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, 1500);

    return () => clearInterval(interval);
  }, [placeholders.length]);

  // 이모지 상태 관리
  const [emojiIndex, setEmojiIndex] = useState(0);

  // 이모지 리스트
  const emojis = ["🍽️", "🍔", "🍣", "🍕", "🍜", "🥩", "🍷", "🥂", "☕", "🍰", "🌮", "🍤"];

  // 1.5초마다 이모지 변경
  useEffect(() => {
    const emojiInterval = setInterval(() => {
      setEmojiIndex((prevIndex) => (prevIndex + 1) % emojis.length);
    }, 1500);

    return () => clearInterval(emojiInterval);
  }, [emojis.length]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.post(`${apiUrl}/search`, {
          params: { q: searchTerm.trim() }
        });
        setSearchResults(response.data.results);
        let results = response.data;
        console.log('results:', results);

        navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`, { state: { results: response.data } });
      } catch (err) {
        console.error('Search error:', err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const toggleExpand = (id) => {
    setExpandedItems(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const renderTags = (result) => {
    return (
      <div className="result-tags">
        {result.tag_blue && <img src="/img/blue_tag.svg" alt="Blue Tag" className="tag-icon" />}
        {result.tag_mi && <img src="/img/mi_tag.png" alt="Mi Tag" className="tag-icon" />}
        {result.tag_you && <img src="/img/you_tag.png" alt="You Tag" className="tag-icon" />}
      </div>
    );
  };

  return (
    <div className="container">
      <div className="header">
        <h1 className="title">
          마인드링크 : 맛집 검색 {emojis[emojiIndex]}  {/* 이모지가 1.5초마다 변경 */}
        </h1>
        <Link to="/" className="home-button">
          홈
        </Link>
      </div>
      <form onSubmit={handleSearch} className="search-container">
        <input 
          type="text" 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input" 
          placeholder={placeholders[placeholderIndex]}
        />
        <button type="submit" className="search-button">🔍</button>
      </form>
      {isLoading && (
          <div className="loading-overlay">
            <img src="/img/loading.gif" alt="검색 중..." className="loading-gif" />
          </div>
        )}
        {error && <p className="error-message">{error}</p>}

      <div className="search-results">
        <h3>검색 결과</h3>
        {searchResults.map((result) => (
          <div key={result.id} className="result-item" style={{'display':'block'}}>
            <div style={{'display':'flex'}}>
              <img src={result.thumbnail} alt={result.store_name} className="result-image" />
              <div>
                {renderTags(result)}
                <div className="result-title">{result.store_name}</div>
              </div>
            </div>
                <div className="address-title">{result.address}</div>
              <div className="result-description">{result.one_line_description}</div>
            <div className="result-content">
              {expandedItems[result.id] && (
                <div className="result-details">
                  <p><strong>[주소]</strong><br/>● {result.address}</p>

                    {/* 키워드 변환 및 렌더링 */}
                    <div><strong>[키워드]</strong><br />
                    {result.keyword && (
                      <div style={{'display':'ruby'}}>
                        {result.keyword
                          .replace(/,\s*/g, '#') // 콤마를 '#'로 바꾸고 공백 없앰
                          .replace(/\s+/g, '') // 모든 공백 제거
                          .split('#') // '#'을 기준으로 분리
                          .filter(Boolean) // 빈 문자열 제거
                          .map((keyword, index) => (
                            <div className='keyword' key={index}>#{keyword}</div> // 각 키워드를 <div>로 감쌈
                          ))}
                      </div>
                    )}
                  </div>

                  {/* <p><strong>[전화번호]</strong><br/>● {result.phone_number}</p> */}
                  <div className="contact-info">
                          <strong className="contact-label">전화번호</strong>
                          <a href={`tel:${result.phone_number}`} className="phone-number">
                            <i className="fas fa-phone"></i> {/* Font Awesome 아이콘 사용 (선택사항) */}
                            {result.phone_number}
                          </a>
                  </div>

                  {/* {result.instagram_url && <p><strong>Instagram:</strong> <a href={result.instagram_url} target="_blank" rel="noopener noreferrer">{result.instagram_url}</a></p>} */}
                  {/* 인스타그램 버튼 */}
                  {result.instagram_url && (
                            <p>
                              <button 
                                className="button instagram-button"
                                onClick={() => window.open(result.instagram_url, '_blank')}
                                style={{ cursor: 'pointer' }}
                              >
                                인스타그램 프로필
                              </button>
                            </p>
                          )}

                  {/* 네이버 버튼 */}
                  <p>
                    <button className="button naver-button"
                      // onClick={() => window.open(result.naver_link ? result.naver_link : `https://map.naver.com/p/search/${encodeURIComponent(result.store_name + ' ' + result.address)}`, '_blank')}
                      onClick={() => window.open(result.naver_link ? result.naver_link : `https://map.naver.com/p/search/${encodeURIComponent(result.store_name)}`, '_blank')}
                      style={{ cursor: 'pointer' }}
                    >
                      {result.naver_link ? "네이버 링크" : "네이버 플레이스"}
                    </button>
                  </p>
                  
                  {/* {result.store_link && <p><strong>Store Link:</strong> <a href={result.store_link} target="_blank" rel="noopener noreferrer">{result.store_link}</a></p>} */}
                  {/* 가게 링크 */}
                  {result.store_link && (
                  <p>
                    <button 
                      className="button store-button"
                      onClick={() => window.open(result.store_link, '_blank')}
                      style={{ cursor: 'pointer' }}
                    >
                      식당 홈페이지
                    </button>
                  </p>
                )}


                  {result.youtube_url && <p><strong>YouTube:</strong> <a href={result.youtube_url} target="_blank" rel="noopener noreferrer">{result.youtube_url}</a></p>}
                </div>
              )}
            </div>
            <button onClick={() => toggleExpand(result.id)} className="button result-button">
              {expandedItems[result.id] ? '접기' : '더보기'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Search;
