// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

.App {
  max-width: 480px;
  margin: 0 auto;
  background-color: white;
  min-height: 100vh;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.container {
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.title {
  font-size: 1.5rem;
  margin: 0;
}

.button {
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.search-container {
  display: flex;
  margin: 20px 0;
}

.search-input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px;
}

.search-button {
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.loading-container, .error-message {
  text-align: center;
  margin-top: 20px;
}

.loading-gif {
  width: 50px;
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,8BAA8B;EAC9B,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,uBAAuB;EACvB,iBAAiB;EACjB,oCAAoC;AACtC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":["body {\n  margin: 0;\n  font-family: Arial, sans-serif;\n  background-color: #f0f0f0;\n}\n\n.App {\n  max-width: 480px;\n  margin: 0 auto;\n  background-color: white;\n  min-height: 100vh;\n  box-shadow: 0 0 10px rgba(0,0,0,0.1);\n}\n\n.container {\n  padding: 20px;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px 10px;\n  background-color: #fff;\n  border-bottom: 1px solid #eee;\n}\n\n.title {\n  font-size: 1.5rem;\n  margin: 0;\n}\n\n.button {\n  background-color: #ff6b6b;\n  color: white;\n  border: none;\n  padding: 8px 15px;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 14px;\n}\n\n.search-container {\n  display: flex;\n  margin: 20px 0;\n}\n\n.search-input {\n  flex-grow: 1;\n  padding: 10px;\n  font-size: 16px;\n  border: 1px solid #ddd;\n  border-radius: 5px 0 0 5px;\n}\n\n.search-button {\n  background-color: #ff6b6b;\n  color: white;\n  border: none;\n  padding: 10px 15px;\n  font-size: 16px;\n  border-radius: 0 5px 5px 0;\n  cursor: pointer;\n}\n\n.loading-container, .error-message {\n  text-align: center;\n  margin-top: 20px;\n}\n\n.loading-gif {\n  width: 50px;\n  height: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
