import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/Game.css';
import { Link } from 'react-router-dom';
import '../css/common.css';

let apiUrl = process.env.REACT_APP_API_URL;

function Game() {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const [gameData, setGameData] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userSelections, setUserSelections] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadGameData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`/data/${gameId}.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch game data');
        }
        const data = await response.json();
        setGameData(data);
      } catch (error) {
        console.error('Error loading game data:', error);
        setError('Failed to load game data. Please try again.');
        // navigate('/'); // Consider if you still want to redirect on error
      } finally {
        setIsLoading(false);
      }
    };

    loadGameData();
  }, [gameId, navigate]);

  const handleSelection = async (questionId, optionId) => {
    const newSelections = { ...userSelections, [questionId]: optionId };

    if (currentQuestionIndex < gameData.questions.length - 1) {
      setUserSelections(newSelections);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setIsLoading(true);
      setError(null);
      try {
        const selectionsArray = Object.entries(newSelections).map(([key, value]) => `${key}-${value}`);
        await axios.post(`${apiUrl}/game`, { selections: selectionsArray.join(','), gameId });
        navigate('/result', { state: { selections: selectionsArray.join(','), gameId } });
      } catch (error) {
        console.error('Error submitting data:', error);
        setError('Failed to submit your selections. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="loading-overlay">
        <img src="/img/loading.gif" alt="Loading..." className="loading-gif" />
      </div>
    );
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  if (!gameData) {
    return <div className="loading">Loading game data...</div>;
  }

  const currentQuestion = gameData.questions[currentQuestionIndex];

  return (
    <div className="game-container">
      <h1>{gameData.title || '밸런스 게임'}</h1>
      <div className="question-container">
        <h2>{currentQuestion.question}</h2>
        <div className="options-container">
          {currentQuestion.options.map((option) => (
            <button
              key={option.id}
              onClick={() => handleSelection(currentQuestion.id, option.id)}
              className="option-button"
            >
              <div>{option.name}</div>
              <p>{option.description}</p>
            </button>
          ))}
        </div>
      </div>
      <div className="progress-bar">
        Question {currentQuestionIndex + 1} of {gameData.questions.length}
      </div>
      <br/>
      <br/>
      <Link to="/" className="home-button">
        홈
      </Link>
    </div>
  );
}

export default Game;