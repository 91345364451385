import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/Intro.css';

let apiUrl = process.env.REACT_APP_API_URL;

function Intro() {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // 추가된 placeholder 관련 상태 관리
  const [placeholderIndex, setPlaceholderIndex] = useState(0);

  // 여러 placeholder 리스트
  const placeholders = [
    "채식 레스토랑 추천",
    "칼국수 맛집을 검색해보세요",
    "파스타 맛집을 찾아보세요",
    "중식 맛집을 추천받아보세요",
    "카페를 검색해보세요",
    "분위기 좋은 맛집 찾기",
    "한식 맛집을 검색해보세요"
  ];

  // 1초마다 placeholder 변경
  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, 1500);

    return () => clearInterval(interval);
  }, [placeholders.length]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.post(`${apiUrl}/search`, {
          params: { q: searchTerm.trim() }
        });
        navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`, { state: { results: response.data } });
      } catch (err) {
        setError('검색 중 오류가 발생했습니다.');
        console.error('Search error:', err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="intro-container">
      <div className="header">
        <img src="/img/mindlink_logo_02.png" alt="logo" class="logo-icon"></img>
        <h1 className="intro-title">마인드링크</h1>
        <button className="login-button" style={{'display':'none'}}>로그인</button>
      </div>

      <div className="balance-game-section">
        <h2>맛집 검색</h2>
        <form onSubmit={handleSearch} className="search-container">
          <input
            type="text"
            placeholder={placeholders[placeholderIndex]}
            className="search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button type="submit" className="search-button">🔍</button>
        </form>
        {isLoading && (
          <div className="loading-overlay">
            <img src="/img/loading.gif" alt="검색 중..." className="loading-gif" />
          </div>
        )}
      {error && <p className="error-message">{error}</p>}
      </div>

      <div className="balance-game-section">
        <h2>밸런스 게임</h2>
        <div className="game-buttons">
          {/* {['mbti', 'food_taste'].map((id) => ( */}
          {['양자택일'].map((id) => (
          // {['양자택일','food_taste'].map((id) => (
            <Link key={id} to={`/games/${id}`} className="game-button">
              <div>
              <img src={`/img/${id}.png`} className="game-image" alt="game-image" />
              </div>
              <p>밸런스 게임 {id}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Intro;
