// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-button {
    display: inline-block;
    padding: 10px 10px;
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .home-button:hover {
    background-color: #45a049;
  }`, "",{"version":3,"sources":["webpack://./src/css/common.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".home-button {\n    display: inline-block;\n    padding: 10px 10px;\n    background-color: #4CAF50;\n    color: white;\n    text-decoration: none;\n    border-radius: 5px;\n    font-size: 16px;\n    transition: background-color 0.3s ease;\n  }\n  \n  .home-button:hover {\n    background-color: #45a049;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
