import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import '../css/Result.css';
import { Link } from 'react-router-dom';
import '../css/common.css';

let apiUrl = process.env.REACT_APP_API_URL;

function Result() {
  const location = useLocation();
  const [resultType, setResultType] = useState(null);
  const [restaurantResults, setRestaurantResults] = useState([]);
  const [expandedItems, setExpandedItems] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentCount, setCurrentCount] = useState(0);

  useEffect(() => {
    const { selections, gameId } = location.state || {};

    if (selections && gameId) {
      const fetchRestaurants = async () => {
        setIsLoading(true);
        setError(null);
        try {
          const response = await axios.post(`${apiUrl}/game`, { selections, gameId });
          console.log('response>>', response);
          setRestaurantResults(response.data.results);
          setResultType(response.data.resultType);
        } catch (err) {
          console.error('Error fetching restaurant recommendations:', err);
          setError('데이터를 불러오는 중 오류가 발생했습니다. 다시 시도해 주세요.');
        } finally {
          setIsLoading(false);
        }
      };

      fetchRestaurants();
    }

  }, [location.state]);

  const toggleExpand = (id) => {
    setExpandedItems(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const renderTags = (result) => {
    return (
      <div className="result-tags">
        {result.tag_blue && <img src="/img/blue_tag.svg" alt="Blue Tag" className="tag-icon" />}
        {result.tag_mi && <img src="/img/mi_tag.png" alt="Mi Tag" className="tag-icon" />}
        {result.tag_you && <img src="/img/you_tag.png" alt="You Tag" className="tag-icon" />}
      </div>
    );
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 11) {
      setPhoneNumber(value);
    }
  };

  const handleSubmit = async () => {
    if (phoneNumber.length === 11) {
      try {
        const response = await axios.post(`${apiUrl}/event`, {
          phoneNumber,
          resultType: resultType.name,
        });
        setShowPopup(false);
        setShowConfirmation(true);
        setCurrentCount(response.data.currentCount);
        alert(response.data.message || '이벤트 응모가 완료되었습니다.');
      } catch (err) {
        console.error('Error submitting phone number:', err);
        if (err.response && err.response.data) {
          alert(err.response.data.error || '이벤트 응모 중 오류가 발생했습니다.');
          setCurrentCount(err.response.data.currentCount);
        } else {
          alert('이벤트 응모 중 오류가 발생했습니다.');
        }
      }
    } else {
      alert('유효한 전화번호를 입력해주세요.');
    }
  };

  const handleShare = async () => {
    const shareData = {
      title: '맛집 유형 테스트',
      text: '나의 맛집 유형은 무엇일까? 맛집 유형 테스트로 알아보세요!',
      // url: frontendUrl // 서비스의 메인 URL 공유
      url: window.location.origin // 서비스의 메인 URL 공유
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData);
        console.log('공유 성공');
      } catch (error) {
        console.log('공유 실패:', error);
      }
    } else {
      // 웹 공유 API를 지원하지 않는 경우, 링크 복사로 대체
      navigator.clipboard.writeText(window.location.origin).then(() => {
        alert('서비스 링크가 클립보드에 복사되었습니다!');
      }, (err) => {
        console.error('링크 복사 실패: ', err);
      });
    }
  };

  return (
    <div className="container">
      <div className="header">
        <h1 className="title">게임 결과</h1>
        <Link to="/" className="home-button">
          홈
        </Link>
      </div>

      {isLoading && (
        <div className="loading-overlay">
          <img src="/img/loading.gif" alt="검색 중..." className="loading-gif" />
        </div>
      )}
      {error && <p className="error-message">{error}</p>}
      
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>커피 이벤트 응모</h2>
            <p>선착순 700명에게 제공!</p>
            <p>전화번호를 입력해주세요</p>
            <input
              type="tel"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="전화번호 입력 (숫자만)"
              maxLength="11"
              pattern="\d*"
            />
            <div className="popup-buttons">
              <button className="submit-button" onClick={handleSubmit}>이벤트 참여</button>
              <button className="cancel-button" onClick={() => setShowPopup(false)}>게임결과확인</button>
            </div>
          </div>
        </div>
      )}

      {showConfirmation && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>응모 완료</h2>
            <p>커피 이벤트에 응모하셨습니다</p>
            <p>현재 응모자 수: {currentCount}명</p>
            <button className="confirm-button" onClick={() => setShowConfirmation(false)}>게임결과확인</button>
          </div>
        </div>
      )}

      {resultType && (
        <div className="result-type-container">
          <h2>{resultType.name}</h2>
          <p>{resultType.description}</p>
          <p className="recommendation-title">추천 레스토랑: {resultType.recommended_restaurant}</p>
          <p>추천 메뉴:</p>
          <ul>
            {resultType.recommended_menu.map((menu, index) => (
              <li key={index}>{menu}</li>
            ))}
          </ul>
          <button onClick={handleShare} className="share-button">서비스 공유하기</button>
        </div>
      )}

      <div className="search-results">
        {restaurantResults.map((result) => (
          <div key={result.id} className="result-item" style={{'display':'block'}}>
            <div style={{'display':'flex'}}>
              <img src={result.thumbnail} alt={result.store_name} className="result-image" />
              <div>
                {renderTags(result)}
                <div className="result-title">{result.store_name}</div>
              </div>
            </div>
            <div className="address-title">{result.address}</div>
            <div className="result-description">{result.one_line_description}</div>
            <div className="result-content">
              {expandedItems[result.id] && (
                <div className="result-details">
                  <p><strong>[주소]</strong><br/>● {result.address}</p>

                  <div><strong>[키워드]</strong><br />
                    {result.keyword && (
                      <div style={{'display':'ruby'}}>
                        {result.keyword
                          .replace(/,\s*/g, '#')
                          .replace(/\s+/g, '')
                          .split('#')
                          .filter(Boolean)
                          .map((keyword, index) => (
                            <div className='keyword' key={index}>#{keyword}</div>
                          ))}
                      </div>
                    )}
                  </div>

                  <div className="contact-info">
                    <strong className="contact-label">전화번호</strong>
                    <a href={`tel:${result.phone_number}`} className="phone-number">
                      <i className="fas fa-phone"></i>
                      {result.phone_number}
                    </a>
                  </div>

                  {result.instagram_url && (
                    <p>
                      <button 
                        className="button instagram-button"
                        onClick={() => window.open(result.instagram_url, '_blank')}
                        style={{ cursor: 'pointer' }}
                      >
                        Instagram 프로필
                      </button>
                    </p>
                  )}

                  <p>
                    <button className="button naver-button"
                      onClick={() => window.open(result.naver_link ? result.naver_link : `https://map.naver.com/p/search/${encodeURIComponent(result.store_name)}`, '_blank')}
                      style={{ cursor: 'pointer' }}
                    >
                      {result.naver_link ? "네이버 링크" : "네이버 플레이스"}
                    </button>
                  </p>
                  
                  {result.store_link && (
                    <p>
                      <button 
                        className="button store-button"
                        onClick={() => window.open(result.store_link, '_blank')}
                        style={{ cursor: 'pointer' }}
                      >
                        식당 홈페이지
                      </button>
                    </p>
                  )}
                  {result.youtube_url && <p><strong>YouTube:</strong> <a href={result.youtube_url} target="_blank" rel="noopener noreferrer">{result.youtube_url}</a></p>}
                </div>
              )}
            </div>
            <button onClick={() => toggleExpand(result.id)} className="button result-button">
              {expandedItems[result.id] ? '접기' : '더보기'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Result;