// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game-container {
  text-align: center;
  padding: 20px;
}

.question-container {
  margin: 20px 0;
}

.options-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.option-button {
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 15px;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.progress-bar {
  margin-top: 20px;
  font-size: 14px;
  color: #666;
}`, "",{"version":3,"sources":["webpack://./src/css/Game.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,WAAW;AACb","sourcesContent":[".game-container {\n  text-align: center;\n  padding: 20px;\n}\n\n.question-container {\n  margin: 20px 0;\n}\n\n.options-container {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.option-button {\n  background-color: #ff6b6b;\n  color: white;\n  border: none;\n  padding: 15px;\n  font-size: 20px;\n  border-radius: 5px;\n  cursor: pointer;\n  font-weight: bold;\n}\n\n.progress-bar {\n  margin-top: 20px;\n  font-size: 14px;\n  color: #666;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
